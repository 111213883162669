.postmanagement {
    padding-left: 5px;
    .content {
        padding-top: 2rem;
        table {
            border-collapse: collapse;
            width: 100%;
            font-size: 1.4rem;
        
            thead {
              border: hidden;
            }
        
            th {
              border: hidden;
            }
        
            th,
            td {
              vertical-align: top;
              text-align: left;
              padding: 8px;
              &.icons {
                > * {
                  margin-right: 5px;
                  cursor: pointer;
                }
              }
            }
        
            tr {
              border-bottom: hidden;
              cursor: pointer;
            }
        
            tr:nth-child(even) {
              background-color: #eee;
            }nt-weight: 500;
            
        }
    }
    
}