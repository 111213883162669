.storesettings {
    padding-left: 5px;
    
    .maintitle {
        font-size: 2.5rem;
        font-weight: 300;
    }
    .content {
        .storelist {
            padding-top: 2rem;
            padding-bottom: 2rem;
            .title {
                font-size: 2rem;
                font-weight: 300;
            }
            .switch {
                padding-top: 1rem;
                display: flex;
                align-items: center;
            }
            .desc {
                padding-top: 2rem;
                .shippingcontainer {
                    padding-top: 2rem;
                    .shippingaddress {
                        padding-top: 1rem;
                        display: flex;
                        width: 500px;
                        justify-content: space-between;
                        .shippingdetail {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}