.pagination {
    list-style: none;
    margin-top: 1rem;
    padding-top: 2rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
    
    .dot {
      padding-right: 1rem;
    }
  
    .hidden {
      display: none;
    }
  
    li {
      font-size: 1.4rem;
      border: hidden;
      min-width: 3rem;
      height: 3rem;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    p {
      margin-left: 1rem;
      font-size: 1.4rem;
      .page {
        color: black;
      }
    }
    .firstlastpage {
      cursor: pointer;
    }
  }
  .active {
    border-radius: 15px;
    background-color: rgb(135, 135, 135);
    color: #fff;
    font-size: 1.4rem;
  }