.createnewstock {
    width: 100%;
    padding-left: 5px;
    .leftdiv {
        width: 70%;
        hr {
            width: 100%;
        }
        .branchselect {
            padding-top: 1rem;
            padding-bottom: 2rem;
            select {
              font-size: 1.5rem;
              width: 100%;
            }
        }
        .search {
            width: 100%;
          }
          .filter {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-top: 2rem;
            padding-bottom: 2rem;
            .sortcategory {
                width: 75%;
                padding-right: 2rem;
                select {
                    width: 100%;
                }
            }
            .sort {
                width: 25%;
                select {
                    width: 100%;
                }
            }
          }
        
          .filterdesc {
              padding-bottom: 2rem;
          }
    }
    table {
        border-collapse: collapse;
        width: 70%;
        font-size: 1.4rem;
    
        thead {
          border: hidden;
        }
    
        th {
          border: hidden;
        }
    
        th,
        td {
          vertical-align: top;
          text-align: left;
          padding: 8px;
          &.icons {
            > * {
              margin-right: 5px;
              cursor: pointer;
            }
          }
        }
    
        tr {
          border-bottom: 1px solid #ccc;
        }
    
        tr:nth-child(even) {
          background-color: #eee;
        }
        .addbutton {
          cursor: pointer;
        }
    }
    .savebutton {
      padding-top: 3rem;
    }
}