.newpost {
    padding-left: 5px;
    .textbox {
        width: 500px;
        padding: 1rem;
    }
    .inputtitle {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: 500;
    }
    textarea {
        display: block;
        font-size: 1.4rem;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 300;
        padding: 1rem;
        width: 500px;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
    }
    .radiotype {
        display: flex;
        flex-direction: row;
        
        .text {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    .itemonposts {
        padding-top: 2rem;
        .title {
            font-size: 1.4rem;
            font-weight: 500;
            padding-top: 2rem;
        }
        .listitem {
            padding-top: 2rem;
            p {
                font-weight: 300;
            }
            .button {
                padding-top: 1rem;
            }
        }
        .inputdiv {
            display: flex;
            align-items: center;
            padding-top: 2rem;
            .button {
                padding-left: 1rem;
            }
        }
    }
    .bottombutton {
        padding-top: 5rem;
        display: flex;
        flex-direction: row;
        .button {
            padding-right: 1rem;
        }
    }
}