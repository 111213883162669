.search {
    margin: 5px 0;
    position: relative;
    flex: 1;
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  
    input[type="text"] {
      display: block;
      font-size: small;
      font-weight:lighter;
      padding: 0.5rem;
      padding-top: 0.5rem;
      padding-left: 2rem;
      width: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      outline: none
    }
  }