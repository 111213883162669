.table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;
    .loader {
      display: flex;
      justify-content: center;
    }
  
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border: hidden;
      }
  
      th {
        border: hidden;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: hidden;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
    }
    .summary {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: start;
  
      .card {
        padding: 1rem;
        .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            color: var(--color-danger);
          }
        }
        button {
          margin-top: 5px;
        }
      }
    }
  }
  .count {
    display: flex;
    align-items: center;
    button {
      border: 1px solid var(--darkblue);
    }
    & > * {
      margin-right: 1rem;
    }
  }

  .checkdiv {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .checktracking {
      width: 15rem;
      height: 2.5rem;
      background-color: black;
      color: white;
      border: hidden;
      cursor: pointer;
      font-size: 1.2rem;
    }
  }

  hr {
    margin-top: 2rem;
    width: 100%;
  }