.auth {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    .alert {
      padding-bottom: 2rem;
    }
    .titleenterphone {
      padding-top: 2rem;
    }
  
  
    .form {
      width: 35rem;
      padding: 1.5rem;
      animation: slide-up 0.5s ease;
      .emaildiv {
        padding-bottom: 1rem;
      }
      .passworddiv {
        padding-top: 1rem;
      }
      .resetemaildiv {
        padding-top: 2rem;
      }
      .loginwithphone {
        display: flex;
        padding-top: 2rem;
      }
      .disclaimer {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        p {
          font-size: 1rem;
          b {
            cursor: pointer;
          }
        }
      }
      .linkdiv {
        padding-left: 5px;
        p {
          font-weight: 500;
        }
      }
      h2 {
        color: black;
        text-align: center;
        font-weight: 300;
        font-size: 1.6rem;
      }
      p {
        font-size: 1.2rem;
      }
      hr {
        width: 100%;
      }

      form {

        .links {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
        }
  
        p {
          text-align: center;
        }
        h3 {
          font-size: 1.2rem;
          font-weight: 300;
        }
      }
      .register {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
      .checkbox {
        display: flex;
        flex-direction: row;
        padding-top: 2rem;
        .lablediv{
          display: flex;
          align-items: center;
          .privacytext {
            font-weight: 300;
          }
          .privacybold{
            font-weight: 500;
            text-decoration: underline;
            margin-left: 0.5rem;
          }
        }
        
      }
      .submitbutton {
        padding-top: 3rem;
        padding-bottom: 1rem;
        button {
          width: 100%;
          height: 3rem;
          background-color: black;
          color: white;
          border: hidden;
          cursor: pointer;
        }
      }
      .submitbuttondisabled {
        padding-top: 3rem;
        padding-bottom: 1rem;
        button {
          width: 100%;
          height: 3rem;
          background-color: grey;
          color: white;
          border: hidden;
        }
      }
      .googleloginbutton {
        padding-top: 1rem;
        button {
          width: 100%;
          height: 3rem;
          background-color: white;
          color: black;
          border: 1px solid black;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            padding-left: 1rem;
          }
        }
      }
      .phoneloginbutton {
        padding-top: 2rem;
        button {
          width: 100%;
          height: 3rem;
          background-color: white;
          color: black;
          border: 1px solid black;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            padding-left: 1rem;
          }
        }
      }

    }
  
    @keyframes slide-up {
      0% {
        transform: translateY(-5rem);
      }
      100% {
        transform: translateY(0);
      }
    }
    @keyframes slide-down {
      0% {
        transform: translateY(5rem);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .img {
      display: none;
    }
  }