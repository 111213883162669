.product {
    .card {
      width: 100%;
      max-width: 500px;
      padding: 1rem;
    }
    form {
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
  
      // textarea {
      //   width: 100%;
      // }
  
      .progress {
        background-color: #aaa;
        border: 1px solid transparent;
        border-radius: 10px;
        .progress-bar {
          background-color: var(--light-blue);
          border: 1px solid transparent;
          border-radius: 10px;
          color: #fff;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0 1rem;
        }
      }
      .productimage {
        .deletebutton{
          cursor: pointer;
          padding: 1rem;
          width: 2rem;
        }
      }
      .radioform {
        padding-top: 2rem;
        padding-bottom: 2rem;
        .yesno {
          padding-top: 1rem;
          display: flex;
          flex-direction: row;
          .textyesno {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
        
      }
      .optionallist {
        padding-bottom: 3rem;
        .addoptionalbutton {
          border: 1px solid black;
          padding-left: 1rem;
          padding-right: 1rem;
          width: 8rem;
          display: flex;
          justify-content: center;
          cursor: pointer;
          span {
            font-size: 1.4rem;
          }
        }
        .yesno {
          padding-top: 1rem;
          display: flex;
          flex-direction: row;
          .textyesno {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
  
  .group {
    border: hidden;
    padding: 2rem;
  }