.newstockmanagement {
    padding-left: 5px;
    .content {
        padding-top: 2rem;
        table {
            border-collapse: collapse;
            width: 100%;
            font-size: 1.4rem;
        
            thead {
              border: hidden;
            }
        
            th {
              border: hidden;
            }
        
            th,
            td {
              vertical-align: top;
              text-align: left;
              padding: 8px;
              &.icons {
                > * {
                  margin-right: 5px;
                  cursor: pointer;
                }
              }
            }
        
            tr {
              border-bottom: hidden;
              cursor: pointer;
            }
        
            tr:nth-child(even) {
              background-color: #eee;
            }nt-weight: 500;
            
        }
    }
}

.componenttoprint {
  padding: 5rem;
  .headerprint {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 3rem;
    .rightheader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .title {
        font-size: 3rem;
        padding-bottom: 2rem;
      }
      .branch {
        padding-bottom: 1rem;
      }
    }

    img {
      width: 200px;
      object-fit: contain;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;

    thead {
      border: hidden;
    }

    th {
      border: hidden;
    }

    th,
    td {
      vertical-align: top;
      text-align: left;
      padding: 8px;
    }
    .qty {
      text-align: center;
    }

    tr {
      border-bottom: hidden;
      cursor: pointer;
    }

    tr:nth-child(even) {
      background-color: #eee;
    }
    
  }

}