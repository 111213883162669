.navbar {
  border-right: 1px solid #ccc;
  min-height: 80vh;

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem;
    background-color: white;

    h4 {
      color: black;
    }
  }

  nav ul {
    .title {
      font-size: 2rem;
      padding-top: 2rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
    }
    li {
      border-bottom: 1px solid #ccc;
      padding: 1rem;
      position: relative;
      a {
        display: block;
        width: 100%;
      }
    }
    h4 {
      padding-top: 2rem;
      padding-left: 1rem;
    }
  }
}

.active {
  cursor: pointer;
}

.active::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  background-color: black;
}