.home {
  .title {
    font-size: 3rem;
    padding-bottom: 2rem;
  }
  .storeselectcontainer {
    display: flex;
    .titleactive {
      font-size: 1.6rem;
      font-weight: 500;
      padding-right: 2rem;
      cursor: pointer;
    }
    .titlenotactive {
      font-size: 1.6rem;
      font-weight: 200;
      padding-right: 2rem;
      cursor: pointer;
    }
  }
  .timeselectcontainer {
    display: flex;
    padding-top: 1rem;
    .titleactive {
      font-size: 1.4rem;
      font-weight: 500;
      padding-right: 2rem;
      cursor: pointer;
    }
    .titlenotactive {
      font-size: 1.4rem;
      font-weight: 200;
      padding-right: 2rem;
      cursor: pointer;
    }
  }
}
.info-box {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.paymentinfo {
  padding-bottom: 3rem;
  .titlepayment {
    font-size: 2rem;
  }
  .descpayment {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 150px;
    .desctitlepayment {
      font-weight: 200;
    }
  }
}

.card {
  border: 1px solid #ccc;
  // border-bottom: 3px solid var(--light-blue);
  padding: 5px;
  background-color: #f5f6fa;
}

.card1 {
  border-bottom: 3px solid #b624ff;
}
.card2 {
  border-bottom: 3px solid #1f93ff;
}
.card3 {
  border-bottom: 3px solid orangered;
}