.table {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    overflow-x: auto;
    @media screen and (max-width: 800px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .leftdiv {
      width: 100%;
      .branchselect {
        padding-top: 1rem;
        padding-bottom: 2rem;
        select {
          font-size: 1.5rem;
          width: 100%;
        }
      }
      .availablestock {
        padding-bottom: 2rem;
        .title {
          font-size: 1.6rem;
          font-weight: 500;
          padding-bottom: 1.6rem;
        }
        .row {
          display: flex;
          .categorycontainer {
            padding-right: 2rem;
            .titlecategory {
              font-size: 1.4rem;
              font-weight: 500;
              padding-bottom: 1rem;
            }
          }
          @media screen and (max-width: 800px) {
            display: grid;
            .categorycontainer {
              padding-top: 2rem;
            }
          }
        }

      }
      .search {
        width: 100%;
      }
      .filter {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .sortcategory {
            width: 75%;
            padding-right: 2rem;
            select {
                width: 100%;
            }
        }
        .sort {
            width: 25%;
            select {
                width: 100%;
            }
        }
      }
    
      .filterdesc {
          padding-bottom: 2rem;
      }
    }
    



  
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border: hidden;
      }
  
      th {
        border: hidden;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        .row {
          display: flex;
        }
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: 1px solid #ccc;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
    }
  }