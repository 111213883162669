.webcoverdiv {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.progress {
    background-color: #aaa;
    border: 1px solid transparent;
    border-radius: 10px;
    .progress-bar {
      background-color: var(--light-blue);
      border: 1px solid transparent;
      border-radius: 10px;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1rem;
    }
  }
  .productimage {
    padding-top: 3rem;
    .deletebutton{
      cursor: pointer;
      padding: 1rem;
      width: 2rem;
    }
  }
.category {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .list {
    padding-bottom: 2rem;
    .subCategory {
      padding-bottom: 2rem;
    }
  }
  .adddialog {
    padding-top: 3rem;
    .adddiv {
      padding-top: 2rem;
      .addbutton {
        border: 1px solid black;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.5rem;
      }
    }
  }
  
  
}

.shippingmethod {
  .radioform {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .yesno {
      padding-top: 1rem;
      display: flex;
      flex-direction: row;
      .textyesno {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    
  }
}