.status {
    width: 100%;
    max-width: 400px;
    margin: 2rem 0;
    .card {
      padding: 1rem;
      border: hidden;
    }
  
    form {
      select {
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
      .tracking {
        padding-bottom: 2rem;
        h4 {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        input {
          font-size: 1.6rem;
          width: 100%;
          padding: 1rem;
        }
      }
    }
  }