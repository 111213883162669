.editpolicy {
  h3 {
    padding-bottom: 2rem;
  }
  h4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  p {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .adddiv {
    padding-top: 2rem;
    .addbutton {
      border: 1px solid black;
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 1.5rem;
      cursor: pointer;
    }
}
}