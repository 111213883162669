.order {
  .orderqr {
    padding-bottom: 3rem;
    .content {
      padding: 5px;
      padding-bottom: 3rem;
      width: 100%;
      overflow-x: auto;
    
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 1.4rem;
    
        thead {
          border: hidden;
        }
    
        th {
          border: hidden;
        }
    
        th,
        td {
          vertical-align: top;
          text-align: left;
          padding: 8px;
          &.icons {
            > * {
              margin-right: 5px;
              cursor: pointer;
            }
          }
        }
    
        tr {
          border-bottom: hidden;
          cursor: pointer;
        }
    
        tr:nth-child(even) {
          background-color: #eee;
        }
        .pending {
          color: orangered;
          font-weight: 500;
        }
        .delivered {
          color: green;
          font-weight: 500;
        }
      }
    }
  }

  .table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;
  
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border: hidden;
      }
  
      th {
        border: hidden;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: hidden;
        cursor: pointer;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
      .pending {
        color: orangered;
        font-weight: 500;
      }
      .delivered {
        color: green;
        font-weight: 500;
      }
    }
  }

}
